import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import { Home } from "../components/landing/Home";
import { HowItWorks } from "../components/works/HowItWorks";
import Register from "../components/register/Register";
import NewLogin from "../components/account/NewLogin";
import NewProfile from "../components/account/profile/NewProfile";
import Cargo from "../components/account/Cargo";
import Identificacion from "../components/account/Identificacion";
import NewDashboard from "../components/account/NewDashboard";
import NewForgotPassword from "../components/account/NewForgotPassword";
import PasswordRecoveryEmail from "../components/account/PasswordRecoveryEmail";
import PasswordRecoveryEmailConfirmation from "../components/account/PasswordRecoveryEmailConfirmation";
import PasswordRecoveryMobileConfirmation from "../components/account/PasswordRecoveryMobileConfirmation";
import PasswordSet from "../components/account/PasswordSet";
import { NotFound } from "../components/account/NotFound";
import { IdentityValidated } from "../components/register/IdentityValidated";
import { PreApproved } from "../components/register/PreApproved";
import { ApplicationReview } from "../components/register/ApplicationReview";
import { NotApproved } from "../components/register/NotApproved";
import DeniedDash from "../components/register/DeniedDash";
import { PrestamosEnLinea } from "../components/common/footer-links/PrestamosEnLinea";
import { PrestamosOnline } from "../components/common/footer-links/PrestamosOnline";
import { PrestamoInmediato } from "../components/common/footer-links/PrestamoInmediato";
import { AvisoDePrivacidad } from "../components/common/footer-links/AvisoDePrivacidad";
import TerminosCondiciones from "../components/common/footer-links/TerminosCondiciones";
import { AboutUs } from "../components/aboutUs/AboutUs";
import { Error } from "../components/common/Error";
import { SecondLoan } from "../components/account/SecondLoan";
import { Manteinance } from "../components/common/Manteinance";
import { BallClipRotate } from "react-pure-loaders";
import CreditReportPage from "../components/creditReport/creditReportPage";
import CreditReportFormPage from "../components/creditReport/creditReportFormPage";
import PasswordSetWithConfirm from "../components/account/PasswordSetWithConfirm";
import { ErrorTimeout } from "../components/common/ErrorTimeout";
import SSO from "../components/register/steps/SSO";
import { RegulatoryInfo } from "../components/register/RegulatoryInfo";
import { LoanReview } from "../components/register/LoanReview";
import { TerminosCondicionesPlazos } from "../components/common/footer-links/TerminosCondicionesPlazos";
import { PromoTermsAndConditions } from "../components/common/footer-links/PromoTermsAndConditions";
import { DespachosDeCobranza } from "../components/common/footer-links/DespachosCobranza";

const Routes = () => {
  // For manteinance, change inManteinance default value to true
  const [inManteinance, setInManteinance] = useState(false);
  const [loading, setLoading] = useState(true);

  // Uncomment for manteinance page and change date range
  useEffect(() => {
    // let startDate   = momentEs("16-08-2020 01:00", "DD/MM/YYYY HH:mm")
    // let endDate     = momentEs("20-08-2020 08:00", "DD/MM/YYYY HH:mm")
    // if(momentEs().isBetween(startDate, endDate)) setInManteinance(true)
    // else setInManteinance(false)
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <div className="router-loading">
          <BallClipRotate loading color="#A3CD3A" />
        </div>
      ) : inManteinance ? (
        <Switch>
          {/* RUTAS EN mantenimiento */}
          {/* <Route exact path='/' component={Home}/> */}
          <Route exact path="/en-mantenimiento" component={Manteinance} />
          <Route path="/*" render={(props) => <Redirect to="/en-mantenimiento" />} />

          {/** automated login page (token required) */}
        </Switch>
      ) : (
        <>
          <Switch>
            {/* <Route exact path='/en-mantenimiento' component={Manteinance} />  */}
            {/* <Route exact path='/' render={props => <Redirect to='/en-mantenimiento'/>} />  */}
            {/* Home */}
            <Route exact path="/" component={Home} />
            {/* Landing sections */}
            <Route path="/como-funciona" component={HowItWorks} />
            <Route
              path="/app"
              render={(props) => {
                window.location.href = "https://play.google.com/store/apps/details?id=mx.app.vivus&utm_source=vivus-website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
              }}
            />

            <Route path="/us" component={AboutUs} />
            {/* Login / Recover password */}
            <Route path="/login" component={NewLogin} />
            <Route path="/sso" render={(props) => <SSO {...props} />} />

            <Route exact path="/registration" render={(props) => <Register {...props} url={0} />} />
            <Route path="/registration/personal-details" render={(props) => <Register {...props} url={1} />} />
            <Route path="/registration/employment-details" render={(props) => <Register {...props} url={2} />} />
            <Route path="/registration/nip-bureau" render={(props) => <Register {...props} url={3} />} />
            <Route path="/registration/identity" render={(props) => <Register {...props} url={4} />} />
            <Route path="/registration-complete" render={(props) => <Register {...props} url={5} />} />
            <Route path="/registration/questionary" render={(props) => <Register {...props} url={6} questionnaire />} />

            <Route path="/application-complete" component={PreApproved} />
            <Route path="/application-review" component={ApplicationReview} />
            <Route path="/identity-validated" component={IdentityValidated} />
            <Route path="/loan-review" component={LoanReview} />
            <Route path="/rejected" component={NotApproved} />
            <Route path="/recupera" component={PasswordRecoveryEmail} />
            <Route path="/recupera-opcion" component={NewForgotPassword} />
            <Route path="/confirmacion-email" component={PasswordRecoveryEmailConfirmation} />
            <Route path="/confirmacion-sms" component={PasswordRecoveryMobileConfirmation} />
            <Route path="/nuevo-password" component={PasswordSet} />
            <Route path="/nuevo-password-confirmar" component={PasswordSetWithConfirm} />
            <Route path="/denied" component={NotApproved} />

            {/* Application */}
            {/* <Route path='/dashboard/initial' component={FirstTimeDash}/> */}
            <Route exact path="/dashboard" component={SecondLoan} />
            <Route path="/perfil" component={NewProfile} />
            {/* <Route path='/dashboard/buro' component={Buro}/> */}
            {/* <Route path="/dashboard/confirm" component={CargoAuto} /> */}
            <Route path="/pre-approved" component={Cargo} />
            <Route path="/dashboard/id" component={Identificacion} />
            <Route path="/dashboard/" component={NewDashboard} />
            {/* <Route path='/borrow-more' component={NewDashboard}/> */}
            {/* LINKS FOOTER */}
            <Route path="/contenido/aviso-de-privacidad" component={AvisoDePrivacidad} />

            <Route path="/informacion-regulatoria" component={RegulatoryInfo} />
            <Route path="/contenido/terminos-y-condiciones" component={TerminosCondiciones} />
            <Route path="/contenido/terminos-y-condiciones-plazos" component={TerminosCondicionesPlazos} />
            <Route path="/contenido/terminos-y-condiciones-promociones" component={PromoTermsAndConditions} />
            <Route path="/contenido/despachos-de-cobranza" component={DespachosDeCobranza} />

            {/* REPEATERS */}
            <Route path="/repeated/credit-check" render={(props) => <Cargo {...props} repeater />} />
            <Route path="/repeated/application/complete" render={(props) => <Register {...props} url={5} repeater />} />
            <Route path="/repeated/application/pre-approved" render={(props) => <PreApproved {...props} repeater />} />
            <Route path="/repeated/application/rejected" render={(props) => <NotApproved {...props} repeater />} />

            {/* ADDITIONAL AMOUNT */}
            <Route path="/additional/application/pre-approved" render={(props) => <Cargo {...props} additional />} />
            <Route path="/additional/application/complete" render={(props) => <PreApproved {...props} additional />} />
            <Route exact path="/historial-crediticio" component={CreditReportPage} />
            <Route exact path="/registro-historial-crediticio" component={CreditReportFormPage} />
            <Route path="/error" component={Error} />
            <Route path="/error-timeout" component={ErrorTimeout} />
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </>
      )}
    </>
  );
};

export default Routes;
